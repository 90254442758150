<template>
  <b-card
    v-if="data"
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <div>
        <b-card-title>Profiles</b-card-title>
        <b-card-text class="font-small-2">
          
        </b-card-text>
      </div>

      <!--<b-dropdown
        variant="link"
        no-caret
        class="chart-dropdown"
        toggle-class="p-0"
        right
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item href="#">
          Last 28 Days
        </b-dropdown-item>
        <b-dropdown-item href="#">
          Last Month
        </b-dropdown-item>
        <b-dropdown-item href="#">
          Last Year
        </b-dropdown-item>
      </b-dropdown>-->
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          class="mb-2 mb-sm-0"
        >
        <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
            </b-media-aside>
            <b-media-body class="my-auto text-center">
              <h3 class="font-weight-bolder mb-0">
                {{ data.total }}
              </h3>
              <b-card-text class="font-small-3 mb-0">
                Sourced
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
          class="mb-2 mb-sm-0"
        >
        <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
            </b-media-aside>
            <b-media-body class="my-auto text-center">
              <h3 class="font-weight-bolder mb-0">
                {{ data.approved }}
              </h3>
              <b-card-text class="font-small-3 mb-0">
                P-Shared
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
          class="mb-2 mb-sm-0"
        >
        <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
            </b-media-aside>
            <b-media-body class="my-auto text-center">
              <h3 class="font-weight-bolder mb-0">
                {{ data.pending }}
              </h3>
              <b-card-text class="font-small-3 mb-0">
                Pending
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
          class="mb-2 mb-sm-0"
        >
        <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
            </b-media-aside>
            <b-media-body class="my-auto text-center">
              <h3 class="font-weight-bolder mb-0">
                {{ data.rejected }}
              </h3>
              <b-card-text class="font-small-3 mb-0">
                Rejected
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
          class="mb-2 mb-sm-0"
        >
        <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
            </b-media-aside>
            <b-media-body class="my-auto text-center">
              <h3 class="font-weight-bolder mb-0">
                {{ data.inprocess }}
              </h3>
              <b-card-text class="font-small-3 mb-0">
                Inprocess
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
          class="mb-2 mb-sm-0"
        >
        <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
            </b-media-aside>
            <b-media-body class="my-auto text-center">
              <h3 class="font-weight-bolder mb-0">
                {{ data.shortlisted }}
              </h3>
              <b-card-text class="font-small-3 mb-0">
                Shortlisted
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
          class="mb-2 mb-sm-0"
        >
        <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
            </b-media-aside>
            <b-media-body class="my-auto text-center">
              <h3 class="font-weight-bolder mb-0">
                {{ data.offered }}
              </h3>
              <b-card-text class="font-small-3 mb-0">
                Offered
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
          class="mb-2 mb-sm-0"
        >
        <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
            </b-media-aside>
            <b-media-body class="my-auto text-center">
              <h3 class="font-weight-bolder mb-0">
                {{ data.joined }}
              </h3>
              <b-card-text class="font-small-3 mb-0">
                Joined
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BDropdown, BDropdownItem,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BDropdown,
    BDropdownItem,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<template>
  <div>
  <!--<section id="dashboard-ecommerce" v-if="data && (role === 'ADMIN' || role === 'MASTER')">-->
    <b-card
      no-body
      class="card-statistics"
    >
      <b-card-header>
        <b-card-title>Statistics

          
        </b-card-title>
        <span v-if="error.length > 0"> {{ error }}</span>
        <b-card-text class="font-small-2 mr-25 mb-0">
          <div class="demo-inline-spacing" >
            <b-form class="demo-inline-spacing">
              <!-- <b-form-group v-if="role === 'ADMIN' || role === 'MASTER' || role === 'HR' || role === 'MIS'"> -->
              <b-form-group v-if="$can('read', 'manager_filter')">
                <v-select
                  v-model="managerSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="managers"
                  :reduce="(option) => option.title"
                  @input="onSelectManagerOption"
                />
              </b-form-group>
              <!-- <b-form-group v-if="role === 'ADMIN' || role === 'MASTER' || role === 'HR' || role === 'MIS' || role === 'ACCOUNT-MANAGER'"> -->
                <b-form-group v-if="$can('read', 'recruiter_filter')">  
                <v-select
                  v-model="recruiterSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="recruiters"
                  :reduce="(option) => option.title"
                  @input="onSelectRecruiterOption"
                />
              </b-form-group>
            </b-form>        
                    <b-dropdown
                      variant="link"
                      no-caret
                      class="chart-dropdown"
                      toggle-class="p-0"
                      right
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="18"
                          class="text-body cursor-pointer"
                        />
                      </template>
                      <b-dropdown-item href="#"
                        @click="getData('all')">
                        All
                      </b-dropdown-item>
                      <b-dropdown-item href="#"
                        @click="getData('today')">
                        Today
                      </b-dropdown-item>
                      <b-dropdown-item href="#"
                        @click="getData('week')">
                        This Week
                      </b-dropdown-item>
                      <b-dropdown-item href="#"
                        @click="getData('lastw')">
                        Last Week
                      </b-dropdown-item>
                      <b-dropdown-item href="#"
                        @click="getData('month')">
                        MTD
                      </b-dropdown-item>
                      <b-dropdown-item href="#"
                        @click="getData('lastm')">
                        Last Month
                      </b-dropdown-item>
                      <b-dropdown-item href="#"
                        @click="getData('quarter')">
                        QTD
                      </b-dropdown-item>
                      <b-dropdown-item href="#"
                        @click="getData('lastq')">
                        Last Quarter
                      </b-dropdown-item>
                      <b-dropdown-item href="#"
                        @click="getData('year')">
                        This Year
                      </b-dropdown-item>
                      <b-dropdown-item href="#"
                        @click="getData('lasty')">
                        Last Year
                      </b-dropdown-item>
                    </b-dropdown>        
          </div>
          
        </b-card-text>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            xl="2"
            sm="3"
          >
            <b-media no-body>
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  variant="light-secondary"
                >
                  <feather-icon
                    size="24"
                    icon="UsersIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ userDashboardData.ProfileShared }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  ProfileShared
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
            xl="2"
            sm="3"
          >
            <b-media no-body>
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  variant="light-info"
                >
                  <feather-icon
                    size="24"
                    icon="UserIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ userDashboardData.Selected }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Selection
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <!-- <b-col
            xl="2"
            sm="3"
          >
            <b-media no-body>
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  variant="light-primary"
                >
                  <feather-icon
                    size="24"
                    icon="UserIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ userDashboardData.Offered }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Offered
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col> -->
          <b-col
            xl="2"
            sm="3"
          >
            <b-media no-body>
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  variant="light-success"
                >
                  <feather-icon
                    size="24"
                    icon="UserIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ userDashboardData.Joiners }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Joiners
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
            xl="2"
            sm="3"
          >
            <b-media no-body>
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  variant="light-danger"
                >
                  <feather-icon
                    size="24"
                    icon="UserIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ userDashboardData.Drops }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Drops
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
            xl="2"
            sm="3"
          >
            <b-media no-body>
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  variant="light-primary"
                >
                  <feather-icon
                    size="24"
                    icon="TrendingUpIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ userDashboardData.Revenue }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Revenue
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  
    <section id="dashboard-ecommerce" v-if="adminData">
    <b-row class="match-height">
      <b-col
        xl="3"
        md="3"
        col
      >
        <admin-revenue :data="adminData.activeData" @refreshData="updateData" />
      </b-col>
      <b-col
        xl="9"
        md="9"
        col
      >
        <admin-statistics :data="adminData.profiles" />
      </b-col>
    </b-row>
    <b-row>
    
      <b-col
        col
      >
        <admin-account-mgr-closure :data="adminData.accountMgrWiseCount" />
      </b-col>
    </b-row>
    <b-row>
    <!--  <b-col
        lg="4"
        md="4"
      >
        <admin-acquisition-states />
      </b-col>
      <b-col
        lg="4"
        md="4"
      >
        <admin-client-closure :data="data.clientClosureItems" />
      </b-col>-->
      <b-col
        col
      >
        <admin-team-closure :data="adminData.recruiterWiseCount" />
      </b-col>
    </b-row>
  </section>
  <section id="dashboard-ecommerce" v-else>
    <h3 class="text-center"> We are Gathering some Data for the Dashboard, Will Be back as soon as we have some Data</h3>
    <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid src="@/assets/images/pages/coming-soon.svg" alt="Dashboard V2" />
    </div>
  </section>
</div>
</template>

<script>
import { BForm, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BMediaAside, BAvatar, BMediaBody, BRow, BCol, BImg, BDropdown, BDropdownItem, BFormGroup } from 'bootstrap-vue'

// import { getUserData } from '@/auth/utils'
// import EcommerceMedal from './EcommerceMedal.vue'
import AdminStatistics from './AdminStatistics.vue'
import AdminRevenue from './AdminRevenue.vue'
import AdminAcquisitionStates from './AdminAcquisitionStates.vue'
import AdminClientClosure from './AdminClientClosure.vue'
import AdminTeamClosure from './AdminTeamClosure.vue'
import AdminAccountMgrClosureVue from './AdminAccountMgrClosure.vue'
import userService from '@/services/user-service'
import AdminAccountMgrClosure from './AdminAccountMgrClosure.vue'
import vSelect from 'vue-select'
import UserService from "@/services/user-service"
import dashboardService from '@/services/dashboard-service'

/* import EcommerceRevenueReport from './EcommerceRevenueReport.vue'
import EcommerceOrderChart from './EcommerceOrderChart.vue'
import EcommerceProfitChart from './EcommerceProfitChart.vue'
import EcommerceEarningsChart from './EcommerceEarningsChart.vue'
import EcommerceCompanyTable from './EcommerceCompanyTable.vue'
import EcommerceMeetup from './EcommerceMeetup.vue'
import EcommerceBrowserStates from './EcommerceBrowserStates.vue'
import EcommerceGoalOverview from './EcommerceGoalOverview.vue'
import EcommerceTransactions from './EcommerceTransactions.vue' */

export default {
  components: {
    BRow,
    BCol,
    AdminStatistics,
    AdminRevenue,
    AdminAcquisitionStates,
    AdminClientClosure,
    AdminTeamClosure,
    BImg,
    AdminAccountMgrClosure,
    BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BMediaAside, BAvatar, BMediaBody, BDropdown, BDropdownItem,
    vSelect, BFormGroup, BForm
    /* EcommerceMedal,
    EcommerceStatistics,
    EcommerceRevenueReport,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    EcommerceCompanyTable,
    EcommerceMeetup,
    EcommerceBrowserStates,
    EcommerceGoalOverview,
    EcommerceTransactions,
    */
  },
  data() {
    return {
        adminData: '',
        role: '',
        recruiterSelected: '',
        managerSelected: '',
        recruiters: [],
        managers: [],
        userDashboardData: [],
        timeSelected: '',
        timeOptions: [],
        error: '',
        recruiter: '',
        manager: '',
        range: ''
    }
  },
  mounted () {
    //console.log('Firebase cloud messaging object', this.$messaging)
  },
  created() {
    /*const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app)*/


    UserService.getAllUsersDdActiveInactive().then(res => {
      console.log(res)
      if(res.status === 'OK') {
      this.recruiters = res.data
      this.recruiters = res.data.filter(item => item.role.toLowerCase() === "TALENT-ADVISOR-SOURCER".toLowerCase() || item.role.toLowerCase() === "ACCOUNT-MANAGER".toLowerCase() || item.role.toLowerCase() === "TALENT-ADVISOR".toLowerCase())

      } else if ((res.status === 401 )) {
        console.log('in 401')
        window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
              this.error = 'Session Expired... Redirecting to Login Page'
              localStorage.clear();
              this.$router.push({ name: "auth-login" });
      } else if ((res.status === 403 )) {
        console.log('in 403')
        this.$router.push({ name: "misc-not-authorized" });
      } 
    })

    
    console.log(this.timeOptions)

      const userD = JSON.parse(localStorage.getItem('userData'))
      this.role = userD.roles[0]
      const title = userD.first_name + ' ' + userD.last_name
      if ( this.role === 'TALENT-ADVISOR' || this.role === 'TALENT-ADVISOR-SOURCER') {
        this.recruiter = userD.emp_id
        this.recruiters = []
        this.managers = []
      } else if ( this.role === 'ACCOUNT-MANAGER') {
        this.manager = userD.emp_id
        this.recruiters = this.recruiters.filter(item => item.manager === this.mgr_id)
        const self = [{
            "id": 111111,
            "title": title,
            "emp_id": this.mgr_id,
            "manager": userD.mgr_id
        }]
        this.recruiters = [...this.recruiters, ...self]  
      } else {
        this.managers = this.recruiters.filter(item => item.role === 'account-manager')
      }
      
      
      //let permit = false
      //if( this.role === 'ADMIN' || this.role === 'MASTER' ){
      this.getUserDashboardData('month')
      this.updateData('today'); 
        /*userService.getAdminBoard('today').then(res => {
          console.log(res.data)
          const active_jobs = res.data.active_jobs.map(item => parseInt(item.active)).reduce((acc, total) => acc + total)
          
          const active_clients = res.data.active_clients.length + 1
          
          const profiles = res.data.total_profiles.reduce((a,c)=> ({
              total: parseInt(a.total) + parseInt(c.total),
              approved: parseInt(a.approved) + parseInt(c.approved),
              pending: parseInt(a.pending) + parseInt(c.pending),
              rejected: parseInt(a.rejected) + parseInt(c.rejected),
              inprocess: parseInt(a.inprocess) + parseInt(c.inprocess),
              shortlisted: parseInt(a.shortlisted) + parseInt(c.shortlisted),
              offered: parseInt(a.offered) + parseInt(c.offered),
              joined: parseInt(a.joined) + parseInt(c.joined),
          })
          
          )    
          this.data = {
            activeData : {
              active_jobs: active_jobs,
              active_clients : active_clients },
            profiles : profiles
          }
          console.log(this.data)
        })*/
      //} 
     
  },
  methods: {
    getUserDashboardData(v) {
      this.range = v
      this.recruiter = this.recruiter && this.recruiter !== null ? this.recruiter : this.recruiterSelected
      this.manager = this.manager && this.manager !== null ? this.manager : this.managerSelected
      console.log('params', this.range, this.recruiter, this.manager, this.role)
      dashboardService.getUserDashboard(v, this.recruiter, this.manager, this.role, this.recruiterSelected, this.managerSelected).then(res => {
        if(res.status === "OK") {
          console.log('user Dashboard data', res.data)
          this.userDashboardData = res.data.dashboard[0]
          this.recruiters = res.data.recruiters
          this.managers = res.data.managers

        } else if ((res.status === 401 )) {
            window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
            localStorage.clear();
            this.$router.push({ name: "auth-login" });
        } else if ((res.status === 400)) {
          this.error = 'No Data Found!'
        } else if ((res.status === 403 )) {
          this.$router.push({ name: "misc-not-authorized" });
        } else {
          this.error = res
        }
      })
    },
    updateData(v) {
      console.log(v)
      //if( this.role === 'ADMIN' || this.role === 'MASTER' ){


      userService.getAdminBoard(v).then(res => {
        if (res.status === "OK") {
          console.log('getAdminBoard', res.data)
        const active_jobs = res.data.active_jobs[0].active//.map(item => parseInt(item.active)).reduce((acc, total) => acc + total)
        
        const active_clients = res.data.active_clients[0].client
        
        /*const profiles = res.data.total_profiles.reduce((a,c)=> ({
            total: parseInt(a.total) + parseInt(c.total),
            approved: parseInt(a.approved) + parseInt(c.approved),
            pending: parseInt(a.pending) + parseInt(c.pending),
            rejected: parseInt(a.rejected) + parseInt(c.rejected),
            inprocess: parseInt(a.inprocess) + parseInt(c.inprocess),
            shortlisted: parseInt(a.shortlisted) + parseInt(c.shortlisted),
            offered: parseInt(a.offered) + parseInt(c.offered),
            joined: parseInt(a.joined) + parseInt(c.joined),
          })
        )*/  
        const profiles = {
            total: res.data.total_profiles[0].total,
            approved: res.data.total_profiles[0].approved,
            pending: res.data.total_profiles[0].pending,
            rejected: res.data.total_profiles[0].rejected,
            inprocess: res.data.total_profiles[0].inprocess,
            shortlisted: res.data.total_profiles[0].shortlisted,
            offered: res.data.total_profiles[0].offered,
            joined: res.data.total_profiles[0].joined
        }
        console.log(profiles) 
        this.adminData = {
            activeData : {
            active_jobs: active_jobs,
            active_clients : active_clients },
          profiles : profiles,
          recruiterWiseCount : res.data.recruiterWiseCount,
          accountMgrWiseCount : res.data.accountMgrWiseCount
        }
        console.log(this.adminData) 
      } else if ((res.status === 401 ) ) {
        console.log('in 401')
        window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
            localStorage.clear();
            this.$router.push({ name: "auth-login" });
      } else if ((res.status === 403 )) {
        console.log('in 403')
          this.$router.push({ name: "misc-not-authorized" });
      } else {
        this.adminData.activeData.profiles = res
      }
      })
      //} 
    },
    getData(v) {
      this.getUserDashboardData(v)
    },
    onSelectManagerOption(option) {
      console.log('option', option)
      this.manager = option
      this.getUserDashboardData(this.range)
    },
    onSelectRecruiterOption(option) {
      this.recruiter = option
      this.getUserDashboardData(this.range)
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
<style scoped>
.v-select {
  width: 200px !important;
}
.card-header {
  padding-left: 1.5rem !important;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}
</style>

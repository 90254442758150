<template>
  <b-card
    v-if="data"
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <div>
        <b-card-title>Clients & Positions</b-card-title>
        <b-card-text class="font-small-2">
          
        </b-card-text>
      </div>

      <b-dropdown
        variant="link"
        no-caret
        class="chart-dropdown"
        toggle-class="p-0"
        right
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item href="#"
          @click="getData('all')">
          All
        </b-dropdown-item>
        <b-dropdown-item href="#"
          @click="getData('today')">
          Today
        </b-dropdown-item>
        <b-dropdown-item href="#"
          @click="getData('week')">
          This Week
        </b-dropdown-item>
        <b-dropdown-item href="#"
          @click="getData('lastw')">
          Last Week
        </b-dropdown-item>
        <b-dropdown-item href="#"
          @click="getData('lastm')">
          Last Month
        </b-dropdown-item>
        <b-dropdown-item href="#"
          @click="getData('quarter')">
          QTD
        </b-dropdown-item>
        <b-dropdown-item href="#"
          @click="getData('lastq')">
          Last Quarter
        </b-dropdown-item>
        <b-dropdown-item href="#"
          @click="getData('year')">
          This Year
        </b-dropdown-item>
        <b-dropdown-item href="#"
          @click="getData('lasty')">
          Last Year
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          xl="6"
          sm="6"
          class="mb-2 mb-sm-0"
        >
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
            </b-media-aside>
            <b-media-body class="my-auto text-center">
              <h3 class="font-weight-bolder mb-0">
                {{ data.active_clients }}
              </h3>
              <b-card-text class="font-small-3 mb-0">
                Active Clients
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
          xl="6"
          sm="6"
          class="mb-2 mb-sm-0"
        >
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
            </b-media-aside>
            <b-media-body class="my-auto text-center">
              <h3 class="font-weight-bolder mb-0">
                {{ data.active_jobs }}
              </h3>
              <b-card-text class="font-small-3 mb-0">
                Active Positions
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BDropdown, BDropdownItem,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    // BCardSubTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      data: {
        activeData: this.data,
        
      },
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getData(v) {
      this.$emit('refreshData', v)
    }
  }
  
}
</script>

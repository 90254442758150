<template>
  <div>
    <b-card
      no-body
      class="card-browser-states"
    >
      <b-card-header>
        <div>
          <b-card-title>Client Wise Closures</b-card-title>
          <b-card-text class="font-small-2">
            Last Updated 1 hr ago
          </b-card-text>
        </div>
      </b-card-header>

      <!-- body -->
      <b-card-body>
        <b-row>
          <b-col
            xl="6"
            sm="6"
          >
            <h5 class="align-self-center mb-0">
              Client
            </h5>
          </b-col>
          <b-col
            xl="3"
            sm="3"
          >
            <h5 class="align-self-center mb-0">
              Shortlisted
            </h5>
          </b-col>
          <b-col
            xl="3"
            sm="3"
          >
            <h5 class="align-self-center mb-0">
              Joined
            </h5>
          </b-col>
          <b-col
            xl="3"
            sm="3"
          >
            <h5 class="align-self-center mb-0">
              Pending
            </h5>
          </b-col>
        </b-row>
        <hr>

        <b-row
          v-for="item in data"
          :key="item.name"
        >
          <b-col
            xl="3"
            sm="3"
          >
            <h6 class="align-self-center mb-1">
              {{ item.name }}
            </h6>
          </b-col>
          <b-col
            xl="3"
            sm="3"
          >
            <b-card-text class="font-small-3 mb-1">
              {{ item.shortlisted }}
            </b-card-text>
          </b-col>
          <b-col
            xl="3"
            sm="3"
          >
            <b-card-text class="font-small-3 mb-1">
              {{ item.joined }}
            </b-card-text>
          </b-col>
          <b-col
            xl="3"
            sm="3"
          >
            <b-card-text class="font-small-3 mb-1">
              {{ item.pending }}
            </b-card-text>
          </b-col>
        </b-row>
      </b-card-body>
      <!--/ body -->
    </b-card>
    <b-card
      no-body
      class="card-browser-states"
    >
      <b-card-header>
        <div>
          <b-card-title>Client Wise Closures</b-card-title>
          <b-card-text class="font-small-2">
            Last Updated 1 hr ago
          </b-card-text>
        </div>

        
      </b-card-header>

      <!-- body -->
      <b-card-body>
        <b-row>
          <b-col
            xl="6"
            sm="6"
          >
            <h5 class="align-self-center mb-0">
              Client
            </h5>
          </b-col>
          <b-col
            xl="3"
            sm="3"
          >
            <h5 class="align-self-center mb-0">
              Shortlisted
            </h5>
          </b-col>
          <b-col
            xl="3"
            sm="3"
          >
            <h5 class="align-self-center mb-0">
              Joined
            </h5>
          </b-col>
          <b-col
            xl="3"
            sm="3"
          >
            <h5 class="align-self-center mb-0">
              Pending
            </h5>
          </b-col>
        </b-row>
        <hr>

        <b-row
          v-for="item in data"
          :key="item.name"
        >
          <b-col
            xl="3"
            sm="3"
          >
            <h6 class="align-self-center mb-1">
              {{ item.name }}
            </h6>
          </b-col>
          <b-col
            xl="3"
            sm="3"
          >
            <b-card-text class="font-small-3 mb-1">
              {{ item.shortlisted }}
            </b-card-text>
          </b-col>
          <b-col
            xl="3"
            sm="3"
          >
            <b-card-text class="font-small-3 mb-1">
              {{ item.joined }}
            </b-card-text>
          </b-col>
          <b-col
            xl="3"
            sm="3"
          >
            <b-card-text class="font-small-3 mb-1">
              {{ item.pending }}
            </b-card-text>
          </b-col>
        </b-row>
      </b-card-body>
      <!--/ body -->
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BDropdown, BDropdownItem, BRow, BCol,
} from 'bootstrap-vue'
/* eslint-disable global-require */
// const $trackBgColor = '#e9ecef'
export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    // BImg,
    BDropdown,
    BDropdownItem,
    // VueApexCharts,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  created() {
  /*  for (let i = 0; i < this.acquisitionData.length; i += 1) {
      const chartClone = JSON.parse(JSON.stringify(this.chart))
      chartClone.options.colors[0] = this.chartColor[i]
      chartClone.series[0] = this.chartSeries[i]
      this.chartData.push(chartClone)
    } */
  },
}
</script>

<template>
  <b-card
    no-body
    class="card-browser-states"
  >
    <b-card-header>
      <div>
        <b-card-title>Acquisitions</b-card-title>
        <b-card-text class="font-small-2">
          Last Updated 1 hr ago
        </b-card-text>
      </div>

      
    </b-card-header>

    <!-- body -->
    <b-card-body>

      
          <vue-apex-charts
            type="donut"
            height="350"
            :options="apexChartData.donutChart.chartOptions"
            :series="apexChartData.donutChart.series"
          />
        
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BMediaAside, BMediaBody, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
/* eslint-disable global-require */
const $trackBgColor = '#e9ecef'
export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    // BImg,
    BDropdown,
    BDropdownItem,
    VueApexCharts,
  },
  data() {
    return {
      apexChartData: {
        donutChart: {
    series: [75, 37, 46, 
    59, 3, 5, 
    4, 7, 3, 
    8, 3, 5, 3, 4],
    chartOptions: {
      legend: {
        show: true,
        position: 'bottom',
        fontSize: '14px',
        fontFamily: 'Montserrat',
      },
      colors: [
      $themeColors.secondary, $themeColors.warning, $themeColors.info, 
      $themeColors.warning, $themeColors.danger, $themeColors.primary, 
      $themeColors.info, $themeColors.warning, $themeColors.primary, 
      $themeColors.danger, $themeColors.sucess, $themeColors.danger, $themeColors.danger, $themeColors.sucess
      ],
      labels: ['Sourced', 'CallIgnored', 'Profile Shared',
      'Review Reject', 'Screen Reject', 'Screen Select', 
      'Inprocess', 'OnHold', 'Shortlisted', 'Rejected', 
      'Offered', 'Not Offered', 'Offer Drop', 'Joined'],
      dataLabels: {
        enabled: true,
        formatter(val) {
          // eslint-disable-next-line radix
          return `${parseInt(val)}%`
        },
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: '2rem',
                fontFamily: 'Montserrat',
              },
              value: {
                show: true,
                fontSize: '1rem',
                fontFamily: 'Montserrat',
                formatter(val) {
                  // eslint-disable-next-line radix
                  return `${parseInt(val)}%`
                },
              },
              total: {
                show: true,
                fontSize: '1.5rem',
                label: 'Operational',
                formatter() {
                  return '31%'
                },
              },
            },
          },
        },
      },
      responsive: [
        {
          breakpoint: 992,
          options: {
            chart: {
              height: 380,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
        {
          breakpoint: 576,
          options: {
            chart: {
              height: 320,
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    name: {
                      fontSize: '1.5rem',
                    },
                    value: {
                      fontSize: '1rem',
                    },
                    total: {
                      fontSize: '1.5rem',
                    },
                  },
                },
              },
            },
            legend: {
              show: true,
            },
          },
        },
      ],
    },
  },
      }
    }
  },
  created() {
    for (let i = 0; i < this.acquisitionData.length; i += 1) {
      const chartClone = JSON.parse(JSON.stringify(this.chart))
      chartClone.options.colors[0] = this.chartColor[i]
      chartClone.series[0] = this.chartSeries[i]
      this.chartData.push(chartClone)
    }
  },
}
</script>

<template>
  <b-card
    no-body
    class="card-browser-states"
  >
    <b-card-header>
      <div>
        <b-card-title>Account Manager wise Profile Count</b-card-title>
        
      </div>

      
    </b-card-header>

    <!-- body -->
    <b-card-body>
      <b-row class="text-center">
        <b-col
          col
        >
          <h5 class="align-self-center mb-0">
            Account Manager
          </h5>
        </b-col>
        <b-col
          col
        >
          <h5 class="align-self-center mb-0">
            Sourced
          </h5>
        </b-col>
        <b-col
          col
        >
          <h5 class="align-self-center mb-0">
            P-Shared
          </h5>
        </b-col>
        <b-col
          col
        >
          <h5 class="align-self-center mb-0">
            Pending
          </h5>
        </b-col>
        <b-col
          col
        >
          <h5 class="align-self-center mb-0">
            Rejected
          </h5>
        </b-col>
        <b-col
          col
        >
          <h5 class="align-self-center mb-0">
            Inprocess
          </h5>
        </b-col>
        <b-col
          col
        >
          <h5 class="align-self-center mb-0">
            Shortlisted
          </h5>
        </b-col>
        <b-col
          col
        >
          <h5 class="align-self-center mb-0">
            Offered
          </h5>
        </b-col>
        <b-col
          col
        >
          <h5 class="align-self-center mb-0">
            Joined
          </h5>
        </b-col>
      </b-row>
      <hr>

      <b-row
        v-for="item in data"
        :key="item.added"
        class="text-center"
      >
        <b-col
          col
        >
          <h6 class="mb-1" style="text-align: left !important;">
            {{ item.account_mgr }}
          </h6>
        </b-col>
        <b-col
          col
        >
          <b-card-text class="font-small-3 mb-1">
            {{ item.total }}
          </b-card-text>
        </b-col>
        <b-col
          col
        >
          <b-card-text class="font-small-3 mb-1">
            {{ item.approved }}
          </b-card-text>
        </b-col>
        <b-col
          col
        >
          <b-card-text class="font-small-3 mb-1">
            {{ item.pending }}
          </b-card-text>
        </b-col>
        <b-col
          col
        >
          <b-card-text class="font-small-3 mb-1">
            {{ item.rejected }}
          </b-card-text>
        </b-col>
        <b-col
          col
        >
          <b-card-text class="font-small-3 mb-1">
            {{ item.inprocess }}
          </b-card-text>
        </b-col>
        <b-col
          col
        >
          <b-card-text class="font-small-3 mb-1">
            {{ item.shortlisted }}
          </b-card-text>
        </b-col>
        <b-col
          col
        >
          <b-card-text class="font-small-3 mb-1">
            {{ item.offered }}
          </b-card-text>
        </b-col>
        <b-col
          col
        >
          <b-card-text class="font-small-3 mb-1">
            {{ item.joined }}
          </b-card-text>
        </b-col>
      </b-row>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BDropdown, BDropdownItem, BRow, BCol,
} from 'bootstrap-vue'
/* eslint-disable global-require */
// const $trackBgColor = '#e9ecef'
export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    // BImg,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    // VueApexCharts,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  created() {
  /*  for (let i = 0; i < this.acquisitionData.length; i += 1) {
      const chartClone = JSON.parse(JSON.stringify(this.chart))
      chartClone.options.colors[0] = this.chartColor[i]
      chartClone.series[0] = this.chartSeries[i]
      this.chartData.push(chartClone)
    } */
  },
}
</script>
